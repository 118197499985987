import Vue from 'vue'
import Router from 'vue-router'

import AuthShell from '@/pages/auth/AuthShell.vue'
import Authlogin from '@/pages/auth/Login.vue'

import DashboardPage from '@/pages/homepage/Index.vue'
import HistoryPage from '@/pages/history/Index.vue'
import ActivityLogPage from '@/pages/activity-log/Index.vue'

import ProfilePage from '@/pages/profile/Index.vue'

// Target
import TargetPage from '@/pages/target/Index.vue'

// case management
import CaseManagementPage from '@/pages/case-management/Index.vue'

// Tracking
import TrackingHistoryPage from '@/pages/tracking-history/Index.vue'

// SavedLocation
import SavedLocationPage from '@/pages/saved-location/Index.vue'

// SearchResult
import SearchResult from '@/pages/result/Index.vue'

// Negative Case
import PageNotFound from '@/pages/errors/NotFound'
import PageForbidden from '@/pages/errors/Forbidden'

//Users pages
import pageUsers from '@/pages/users/Index.vue'

// License auth
// import PageLicense from '@/pages/auth/License.vue'
// import PageLicenseExpired from '@/pages/auth/Expired.vue'

// license pages
// import LicenseShell from '@/pages/license/LicenseShell.vue'
import pageLicenseIndex from '@/pages/license/Index.vue'
// import pageLicenseHistory from '@/pages/license/History.vue'
// import pageLicenseForm from '@/pages/license/Form.vue'
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'DashboardPage',
            component: DashboardPage,
            meta: {
                requiresAuth: true,
            },
        },
        // target
        {
            path: '/target', 
            name: 'TargetPage',
            component: TargetPage,
            meta: {
                requiresAuth: true,
            },
        },
        // case
        {
            path: '/case-management', 
            name: 'CaseManagementPage',
            component: CaseManagementPage,
            meta: {
                requiresAuth: true,
            },
        },

        // license
        {
            path: '/license',
            name: 'pageLicenseIndex',
            component: pageLicenseIndex,
            meta: {
                requiresAuth: true,
            },
        },
        
        // users
        {
            path: '/users',
            name: 'pageUsers',
            component: pageUsers,
            meta: {
                requiresAuth: true,
            },
        },        
        // tracking
        {
            path: '/tracking-history', 
            name: 'TrackingHistoryPage',
            component: TrackingHistoryPage,
            meta: {
                requiresAuth: true,
            },
        },
        
        // location
        {
            path: '/saved-location', 
            name: 'SavedLocationPage',
            component: SavedLocationPage,
            meta: {
                requiresAuth: true,
            },
        },

        // result
        {
            // path: '/result/:key?/:from_saved_location?', 
            // path: '/result/:key?/:target_id?/:from_saved_location?', 
            path: '/result/:key?/:target_id?', 
            name: 'SearchResult',
            component: SearchResult,
            meta: {
                requiresAuth: true,
            },
        },

        // result hitory
        {
            path: '/result-history/:data_type?/:history_data?', 
            name: 'SearchResultHistory',
            component: SearchResult,
            meta: {
                requiresAuth: true,
            },
        },

        // history
        {
            path: '/history',
            name: 'HistoryPage',
            component: HistoryPage,
            meta: {
                requiresAuth: true,
            },
        },{
            path: '/activity-log',
            name: 'ActivityLogPage',
            component: ActivityLogPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/profile',
            name: 'ProfilePage',
            component: ProfilePage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/login',
            component: AuthShell,
            children: [
                {
                    path: '',
                    name: 'Authlogin',
                    component: Authlogin,
                    meta: {
                        layout: 'blank'
                    },
                }
            ]
        },
        // forbidden
        {
            path: '/errors/forbidden',
            name: 'PageForbidden',
            component: PageForbidden,
            meta: {
                layout: 'blank'
            },
        },

        // not found
        {
            path: '*',
            name: 'PageNotFound',
            component: PageNotFound,
            meta: {
                layout: 'blank'
            },
        },
        
    ]
});