<template lang="pug">
    .container
        section.box.my-8
            header.box__header.flex.items-center.justify-between.mb-8
                h2.text-2xl.font-bold List of License
                //- button.btn-primary(
                //-     @click="modalCase = !modalCase; form_type = true;"
                //- ) Add New License

            .box__body
                .main-table
                    table.table
                        thead
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}

                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")
                            template(v-else-if="list.data && list.data.length > 0")
                                tr(v-for="(data, index) in list.data" :key="index")
                                    td
                                        .text-sm {{ data.licenseName }}
                                    td
                                        .text-sm {{ data.activatedAt }}
                                    td
                                        .text-sm {{ data.expiredAt }}
                                    
                                    //- td
                                    //-     .flex.items-center.justify-end

                                    //-         button.btn-icon(
                                    //-             @click="editModal(data.id)"
                                    //-             v-tooltip="'Edit'"
                                    //-         )
                                    //-             IconSet(type="edit" size="s")

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available


        .overlay(v-bind:class="{'is-show': modalCase}")
            .overlay__container
                .box-overlay
                    header.box-overlay__header.flex.items-center.justify-between
                        h2.text-xl.font-bold {{ form_type ? 'Add' : 'Edit'}} License
                        button.btn-icon(
                            @click="modalCase = !modalCase"
                        )
                            IconSet(
                                type="close"
                            )
                    form.box-overlay__body(@submit="submit($event)")
                        .field
                            .field__label
                                label.block.text-sm.font-bold License Name <sup>*</sup>
                            .field__form
                                input.field-style(type="text" placeholder="Write license name here" v-model="model.license_name")
                                .pt-3
                                    .message.text-xs.text-red-500(v-if="$v.model.license_name.$error") This license name is required &amp; max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_license_name !== ''") {{ error_license_name }}

                        .field
                            .field__label
                                label.block.text-sm.font-bold License Key (Serial Number) <sup>*</sup>
                            .field__form
                                input.field-style(type="text" placeholder="Write license key here" v-model="model.license_key")
                                .pt-3
                                    .message.text-xs.text-red-500(v-if="$v.model.license_key.$error") This license name is required &amp; max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_license_key !== ''") {{ error_license_key }}

                        .grid.grid-cols-3.gap-8
                            .field
                                .field__label
                                    label.block.text-sm.font-bold Quantity <sup>*</sup>
                                .field__form
                                    input.field-style(type="number" placeholder="Write quantity here" v-model="model.quantity")
                                    .py-3
                                        .message.text-xs.text-red-500(v-if="$v.model.quantity.$error") This quantity is required &amp; numeric.
                                        .message.text-xs.text-red-500(v-if="error_quantity !== ''") {{ error_quantity }}
                            .field
                                .field__label
                                    label.block.text-sm.font-bold Installed In <sup>*</sup>
                                .field__form
                                    input.field-style(type="text" placeholder="Write Installed here" v-model="model.installed_in")
                                    .py-3
                                        .message.text-xs.text-red-500(v-if="$v.model.installed_in.$error") This installed in is required &amp; max length is 255 char.
                                        .message.text-xs.text-red-500(v-if="error_installed_in !== ''") {{ error_installed_in }}
                            .field
                                .field__label
                                    label.block.text-sm.font-bold Binded In <sup>*</sup>
                                .field__form
                                    input.field-style(type="text" placeholder="Write Binded here" v-model="model.binded_in")
                                    .py-3
                                        .message.text-xs.text-red-500(v-if="$v.model.binded_in.$error") This binded in is required &amp; max length is 255 char.
                                        .message.text-xs.text-red-500(v-if="error_binded_in !== ''") {{ error_binded_in }}

                        .grid.grid-cols-2.gap-8
                            .field.field--radio.mb-4
                                .field__label
                                    label.block.text-sm.font-bold Status <sup>*</sup>
                                .field__form
                                    .ui-radio.mr-4
                                        input(type="radio" name="status" value="active" v-model="model.status")
                                        .style-radio
                                            .radio
                                            strong Active
                                    .ui-radio
                                        input(type="radio" name="status" value="inactive" v-model="model.status")
                                        .style-radio
                                            .radio
                                            strong Inactive
                                    .pt-3
                                        .message.text-xs.text-red-500(v-if="$v.model.status.$error") This status is required.
                                        .message.text-xs.text-red-500(v-if="error_status !== ''") {{ error_status }}

                            .field
                                .field__label
                                    label.block.text-sm.font-bold Source
                                .field__form
                                    .relative
                                        .field-style.field-style--mirroring.field-style--loading(v-if="status_source_list.status === 'loading'")
                                            Spinner(show="true" size="32")

                                        select.field-style(v-model="model.source_label")
                                            option(value="") - No Source -
                                            template(v-if="checkSource()")
                                                option(v-for="(source,index) in sourceListData" :key="index" :value="index") {{ source }}
                                        .field-icon
                                            IconSet(type="chevron-down")
                                    .pt-3
                                        .message.text-xs.text-red-500(v-if="error_source_label !== ''") {{ error_source_label }}

                        .grid.grid-cols-2.gap-8
                            .field
                                .field__label
                                    label.block.text-sm.font-bold Activated Date
                                .field__form
                                    .mr-3
                                        .relative.mr-3.field-style.field-style--license
                                            date-range-picker.field-style__date(
                                                ref="picker" 
                                                v-model="dateRangeActivatedDate" 
                                                @update="pickerUpdate($event)" 
                                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                                :date-format="dateFormat" 
                                                :singleDatePicker="true"
                                                opens="right"
                                                :ranges="default_ranges"
                                                :autoApply="true"
                                            )
                                                div(
                                                    slot="input" 
                                                    slot-scope="picker")
                                                        | {{ picker.startDate ? singleDateFormat(picker.startDate) : '-' }}

                                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                                                IconSet(type="calendar")

                                        button.link.text-xs.mt-3.text-red-100(v-if="model.activated_date !== ''" type="button" v-tooltip="'Clear activated date'" @click="setEmpyDate('activated')")
                                            | Clear Activated Date

                                    .py-3
                                        //- .message.text-xs.text-red-500(v-if="$v.model.activated_date.$error") This activated date in is required.
                                        .message.text-xs.text-red-500(v-if="error_activated_date !== ''") {{ error_activated_date }}

                            .field
                                .field__label
                                    label.block.text-sm.font-bold Expired Date
                                .field__form
                                    .mr-3
                                        .relative.mr-3.field-style.field-style--license
                                            date-range-picker.field-style__date(
                                                ref="picker" 
                                                v-model="dateRangeExpiredDate" 
                                                @update="pickerUpdate($event)" 
                                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                                                :date-format="dateFormat" 
                                                :singleDatePicker="true"
                                                opens="right"
                                                :ranges="default_ranges"
                                                :autoApply="true"
                                            )
                                                div(
                                                    slot="input" 
                                                    slot-scope="picker")
                                                        | {{ picker.startDate ? singleDateFormat(picker.startDate) : '-' }}

                                            .pointer-events-none.absolute.inset-y-0.right-0.flex.items-center.px-2
                                                IconSet(type="calendar")

                                        button.link.text-xs.mt-3.text-red-100(v-if="model.expired_date !== ''" type="button" v-tooltip="'Clear expired date'" @click="setEmpyDate('expired')")
                                            | Clear Expired Date

                                    .py-3
                                        //- .message.text-xs.text-red-500(v-if="$v.model.expired_date.$error") This expired date in is required.
                                        .message.text-xs.text-red-500(v-if="error_expired_date !== ''") {{ error_expired_date }}
                            
                        .field.flex.items-center.justify-end
                            button.btn-primary(
                                type="submit" 
                            ) SAVE                                

</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import IconSet from '@/components/IconSet.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { required, maxLength, numeric } from "vuelidate/lib/validators";

export default {
    name: "License",
    components: {
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        IconSet,
        DateRangePicker,
    }, 
    data(){
        return {
            // tableThead: [],
            total_data: 0,
            items_per_page: 100,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,
            modalCase: false,
            form_type: true, 
            license_id_selected: '',                       

            model: {
                license_name: '',
                license_key: '',
                quantity: '',
                installed_in: '',
                binded_in: '',
                status: '',
                activated_date: '',
                expired_date: '',
                source_label: '',
            },  
            error_license_name: '',
            error_license_key: '',
            error_quantity: '',
            error_installed_in: '',
            error_binded_in: '',
            error_status: '',
            error_activated_date: '',
            error_expired_date: '',
            error_source_label: '',

            dateRangeActivatedDate: {
                startDate: '',
                endDate: '',
            },
            dateRangeExpiredDate: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                // 'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                // 'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },          
        };
    },
    validations: {
        model: {
            license_name: {
                required,
                maxLength: maxLength(255)
            },
            license_key: {
                required,
                maxLength: maxLength(255)
            },
            quantity:  {
                required,
                numeric,
            },
            installed_in:  {
                required,
                maxLength: maxLength(255)
            },
            binded_in:  {
                required,
                maxLength: maxLength(255)
            },
            status:  {
                required,
            },
            activated_date:  {
                // required,
            },
            expired_date:  {
                // required,
            },
            source_label:  {
                // required,
            },
        },
    },    
    computed: {
        ...mapState('license', [
            'status_list',
            'list',
            'detail',
            'status_source_list',
            'source_list',
        ]),
        ...mapState('auth', [
            'user',
        ]),
        route_name() {
            return this.$route.name;
        },
        sourceListData() {
            if (this.source_list.data && Object.keys(this.source_list.data).length > 0){
                let data = this.source_list.data
                console.log({data})
                return data;
            }
            return {};
        },        
        tableThead() {
            let tableThead = [
                {
                    name: 'License Name',
                },
                {
                    name: 'Start Date',
                },
                {
                    name: 'End Date',
                },
            ];

            // if (this.checkActionButton())
            //     tableThead.push({
            //         name: 'Action',
            //         action: true,
            //     });

            return tableThead;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageLicenseIndex') {
                if (oldData !== 'pageLicenseIndex') {
                    this.items_per_page = 10;
                    this.page = 0;
                    this.keyword = '';
                    this.$store.commit('license/setList', {});
                }

                this.getData();
            }
        },
        tableThead() {},
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        modalCase(newData) {
            if (newData === false)
                this.reset();
        },
        dateRangeActivatedDate(newData) {
            let date = '';
            const check_date = moment(newData.startDate).format('YYYY-MM-DD');

            if (check_date !== 'Invalid date')
                date = check_date;

            this.model.activated_date = date;
        },
        dateRangeExpiredDate(newData) {
            let date = '';
            const check_date = moment(newData.startDate).format('YYYY-MM-DD');

            if (check_date !== 'Invalid date')
                date = check_date;

            this.model.expired_date = date;
        },
        detail(newData) {
            this.model.license_name = '';
            this.model.license_key = '';
            this.model.quantity = '';
            this.model.installed_in = '';
            this.model.binded_in = '';
            this.model.source_label = '';
            this.model.status = '';
            this.setEmpyDate('activated');
            this.setEmpyDate('expired');
            if (newData.data){
                let dataNewData = newData.data
                this.license_id_selected = (dataNewData.id ? dataNewData.id : '');
                this.model.license_name = dataNewData.licenseName || '';
                this.model.license_key = dataNewData.licenseKey || '';
                this.model.quantity = dataNewData.qty || '';
                this.model.installed_in = dataNewData.installedIn || '';
                this.model.binded_in = dataNewData.bindedIn || '';
                this.model.source_label = dataNewData.sourceLabel || '';
                this.model.status = dataNewData.status?(dataNewData.status.toLowerCase() === 'active' ? 'active' : 'inactive') : '';
                // this.model.activated_date = (dataNewData.activatedDate ? dataNewData.activatedDate || '');
                // this.model.expired_date = (dataNewData.expiredDate ? dataNewData.expiredDate || '');

                if (dataNewData.activatedAt && dataNewData.activatedAt.toLowerCase() !== 'permanent') {
                    const date = moment(dataNewData.activatedAt, 'YYYY-MM-DD').toDate();
                    this.dateRangeActivatedDate = {
                        startDate: date,
                        endDate: date,
                    };
                }
                //  else {
                //     this.setEmpyDate('activated');
                // }

                if (dataNewData.expiredAt && dataNewData.expiredAt.toLowerCase() !== 'permanent') {
                    const date = moment(dataNewData.expiredAt, 'YYYY-MM-DD').toDate();
                    this.dateRangeExpiredDate = {
                        startDate: date,
                        endDate: date,
                    };
                }
                //  else {
                //     this.setEmpyDate('expired');
                // }
            }
        },
        'model.license_key': function(newData) {
            if (newData !== '' && newData !== null){
                // console.log('model.license_key - newData')
                // console.log({newData})
                this.model.license_key = newData.replace(/\s+/g, '-');
            }
        },      
    },
    methods: {
        checkActionButton() {
            return (Object.keys(this.user).length > 0 && this.user.role && this.user.role.label === 'superadmin')
        },
        checkSource() {
            return (this.source_list.data && Object.keys(this.source_list.data).length > 0)
        },
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                        this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_license_name = '';
                this.error_quantity = '';
                this.error_installed_in = '';
                this.error_binded_in = '';
                this.error_status = '';
                this.error_activated_date = '';
                this.error_expired_date = '';
                this.error_source_label = '';

            } else if (empty !== true && errors !== null) {
                // this.error_license_name = (errors.license_name ? errors.license_name : '');
                // this.error_quantity = (errors.quantity ? errors.quantity : '');
                // this.error_installed_in = (errors.installed_in ? errors.installed_in : '');
                // this.error_binded_in = (errors.binded_in ? errors.binded_in : '');
                // this.error_status = (errors.status ? errors.status : '');
                // this.error_activated_date = (errors.activated_date ? errors.activated_date : '');
                // this.error_expired_date = (errors.expired_date ? errors.expired_date : '');

                this.error_license_name = errors.license_name || '';
                this.error_quantity = errors.quantity || '';
                this.error_installed_in = errors.installed_in || '';
                this.error_binded_in = errors.binded_in || '';
                this.error_status = errors.status || '';
                this.error_activated_date = errors.activated_date || '';
                this.error_expired_date = errors.expired_date || '';
                this.error_source_label = errors.source_label || '';
            }
        },
        /* eslint-disable no-unused-vars */
        dateFormat(classes, date) {
            // if (!classes.disabled) {
            //     classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            // }
            return classes;
        },
        singleDateFormat(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        setEmpyDate(type=null) {
            if (type !== null) {
                const default_date = {
                    // startDate: '',
                    // endDate: '',
                    startDate: null,
                    endDate: null,
                };

                switch(type) {
                    case 'activated':
                        this.dateRangeActivatedDate = default_date;
                        break;

                    case 'expired':
                        this.dateRangeExpiredDate = default_date;
                        break;

                    default:
                        // code block
                };
            }
        },

        getData() {
            // for dispatch get data
            if (this.route_name === 'pageLicenseIndex') {
                // async getList({ state, commit, dispatch }, [limit=null, page=null, client_id=null, status=null]) {
                this.$store.dispatch('license/getList', [
                    this.items_per_page, 
                    (this.page + 1), 
                    // for testing only !!!
                    // 2
                    
                    // true value !!!
                    (Object.keys(this.user).length > 0 && this.user.client && this.user.client.id ? this.user.client.id : '')
                ]);
                this.license_id_selected = '';
            }
        },
        pageChange(page) {
            this.page = page;
        },
        sizeChange() {
            this.page = 0;
        },
        editModal(id) {
            this.modalCase = !this.modalCase;
            this.form_type = false;
            this.license_id_selected = id;
            this.getDetail(id);
        },

        getDetail(id) {
            this.reset();

            Event.$emit('showLoading', true);

            this.$store.dispatch('license/getDetail', id)
            .then((resp) => {
                if (resp === 'success') {

                    Event.$emit('showLoading', false);

                } else {
                    Event.$emit('error');
                }
            });

        }, 
        reset() {
            this.$store.commit('license/setDetail', {});
            this.license_id_selected = '';
            this.setEmptyModel();
            // reset vuelidate
            this.$nextTick(() => { this.$v.$reset(); });
            this.setErrors(true);
            window.scrollTo(0,0);
        },
        
        submit(e) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // Event.$emit('error', 'Please fill the form correctly.');
                // Event.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            Event.$emit('showLoading', true);

            let model_data = Object.keys(this.model).map((key) => this.model[key]);
            // let model_data = Object.keys(this.model).map(
            //     (key) => (key !== 'assign_id') ? this.model[key] : [JSON.stringify(this.model[key])]
            // );

            if (this.form_type === true) {

                this.$store.dispatch('license/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        Event.$emit('success', 'Data successfully created!');
                        this.modalCase = false;
                        this.getData();

                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });

            // edit/update
            } else {

                model_data = [this.license_id_selected].concat(model_data);

                this.$store.dispatch('license/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        Event.$emit('success', 'Data successfully created!');
                        this.modalCase = false;
                        this.getData();

                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });
            }
        },    
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    created() {
        this.getData();
    },
    mounted() {
        // 
    },
}
</script>