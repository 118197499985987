<template lang="pug">
    .app-layouts__header
        header.header-app
            .container
                .flex.items-center.justify-between
                    .flex.items-center
                        h1.font-bold
                            router-link(:to="user && user.role && user.role.label === 'tracking' ? '/tracking-history' : '/'").mr-4 
                                | {{ user && user.role && user.role.label && user.role.label === 'tracking' ? 'NEMESIS TRACK DASHBOARD' : 'DASHBOARD NEMESIS' }}
                        button.search-box(
                            v-if="checkModule('overall', 'recent-location')"
                            @click="modalTracking = !modalTracking"
                        )
                            IconSet(type="search" size="s")
                            span.ml-2 Search New Target
                    nav.flex.items-center.justify-end.menus
                        //- router-link(to="/") Dashboard
                        .account(v-if="firstMenu.length > 0")
                            router-link(v-for="(item,index) in firstMenu" :key="index" :to="item.url") {{ item.name }}

                        .users(v-if="secondMenu.length > 0")
                            router-link(v-for="(item,index) in secondMenu" :key="index" :to="item.url") {{ item.name }}

                        //- router-link(to="/profile") {{ user && user.name ? user.name : '-' }}
                        router-link(to="/profile")
                            IconSet(type="user" size="s")

        //- section.header-breadcrumb
            .container
                nav
                    ul
                        li
                            router-link(to="/") Dashboard
                        li
                            router-link(to="/") Pages
                        li
                            router-link(to="/") Sub Page

                        li
                            span Current page


        .overlay(v-bind:class="{'is-show': modalTracking}")
            .overlay__container
                .box-overlay
                    header.box-overlay__header.flex.items-center.justify-between
                        h2.text-xl.font-bold Search New Target
                        button.btn-icon(
                            @click="modalTracking = !modalTracking"
                        )
                            IconSet(
                                type="close"
                            )

                    .box-overlay__body
                        .callout.flex.items-center.justify-between.mb-8
                            .flex.items-center.justify-center.w-full(v-if="status_quota_info.status === 'loading'")
                                Spinner(show="true" size="24")
                            template(v-else)
                                template(v-if="Object.keys(quota_info).length > 0")

                                    .box-out.text-center(v-if="quota_info.used === quota_info.quota")
                                        h3.text-xl.font-bold.mb-2 Out of Quota
                                        p.mb-1.font-bold You have used {{ quota_info.used }} out of {{ quota_info.quota }} daily quota.
                                        p Your quota has run out, please activate your access now by calling the admin.

                                    template(v-else)
                                        p Your Daily Request Usage
                                        p.font-bold {{ quota_info.used || quota_info.used === 0 ? quota_info.used : '-' }}/{{ quota_info.quota || quota_info.quota === 0 ? quota_info.quota : '-' }}

                                .flex.items-center.justify-center.w-full(v-else)
                                    button.btn-icon-24(@click="getQuotaInfo()" v-tooltip="'Try again.'")
                                        IconSet(type="refresh-cw")


                        template(v-if="status_quota_info.status !== 'loading' && Object.keys(quota_info).length > 0 && quota_info.used !== quota_info.quota")
                            .relative
                                .field
                                    .field__label
                                        label(for="search") Search a target
                                    .field__form
                                        input.field-style#input-search-target(
                                            type="text"
                                            placeholder="Type  “081228075449”  “Ilham prabowo” (write min 3 characters)"
                                            v-model="target_keyword" 
                                            @keyup="searchTarget()"
                                        )
                                        .field-icon
                                            IconSet(type="search")
                                        .mt-2.text-xs Search target by phone number or name
                                        //- Jika target sudah terpilih muncul ini
                                        .field-style.field-style--mirroring(v-if="Object.keys(target_selected).length > 0")
                                            .flex.items-center.w-full.justify-between
                                                .flex-grow.flex.items-center
                                                    //- .mr-4
                                                        .w-3.h-3.rounded-full(:style="'background-color: '+ ( target_selected.label ? target_selected.label:'white') +';'")
                                                    //- .mr-4
                                                        figure.w-8.h-8.rounded-full.overflow-hidden
                                                            img(:src="target_selected.photo ? target_selected.photo : (defaultImage?defaultImage:getDefaultImage)" @error="getDefaultImage")
                                                    span {{target_selected.target_name}} {{ target_selected.clues && target_selected.clues.length > 0 ? filterMsisdn(target_selected.clues) : '' }}

                                                button.btn-icon-24(@click="setEmptyTarget" v-tooltip="'Remove target'")
                                                    IconSet(type="close")

                            //- show while typing the field - autocomplete
                            .block.box-searching(v-if="status_target_list.status === 'loading'")
                                .flex.items-center.justify-center.w-full
                                    Spinner(show="true" size="24")
                            .block.box-searching(v-else-if="target_list_data && target_list_data.length > 0")
                                //- target's list
                                label.block.p-4 Choose a Target
                                ul
                                    li.cursor-pointer(v-for="(data,index) in target_list_data" @click="selectTarget(data)")
                                        .flex-grow.flex.items-center
                                            //- .mr-4
                                                .w-3.h-3.rounded-full(:style="'background-color: '+ ( data.label ? data.label:'white') +';'")
                                            //- .mr-4
                                                figure.w-8.h-8.rounded-full.overflow-hidden
                                                    img(:src="data.photo ? data.photo : (defaultImage?defaultImage:getDefaultImage)" @error="getDefaultImage")

                                            span {{ data.target_name }} {{ data.clues && data.clues.length > 0 ? filterMsisdn(data.clues) : '' }}

                            //- if the target not found, show this information
                            .block.box-searching(v-else-if="target_list.data && target_list.data.length == 0 && target_keyword.length > 2")
                                .px-4
                                    .flex.items-center.justify-between
                                        span.block "{{ target_keyword }}" not found.
                                        //- router-link.link(
                                        //-     @click.native="searchTargetAdvance(false)"
                                        //-     :to="{ name: 'pageTargetCreate', params: {} }"
                                        //- ) Create Target
                                    //- .mt-8.text-center
                                        small.text-xs.text-blue-500 This process will discontinue if you want to create a new target

                            .field.flex.items-center.justify-end.mt-10
                                button.btn-primary(
                                    type="button"
                                    :class="Object.keys(target_selected).length > 0 ? '' : 'btn-disabled cursor-not-allowed'"
                                    :disabled="Object.keys(target_selected).length > 0 ? false : 'disabled'"
                                    @click="search()"
                                ) SEARCH TARGET

</template>

<script>
import { mapState } from 'vuex';
import IconSet from '@/components/IconSet.vue';
import Spinner from '@/components/Spinner.vue';
import { encrypt } from '@/util/crypto';

export default {
    name: "Tracking",
    components: {
        IconSet,
        Spinner,
    },
    data() {
        return {
            modalTracking: false,

            target_keyword: '',
            target_selected: {},
            target_list_data: [],
        };
    },
    computed: {
        ...mapState({
            status_target_list: (state) => state.target.status_search_list,
            target_list: (state) => state.target.search_list,
            status_quota_info: (state) => state.dashboard.status_quota_info,
            quota_info: (state) => state.dashboard.quota_info,
        }),
        ...mapState('auth', ['user']),
        route() {
            return this.$route;
        },
        firstMenu() {
            let firstMenu = [];

            if (this.checkModule('log_activity'))
                firstMenu.push({
                    name: 'Tracking History',
                    url: '/tracking-history'
                });

            if (this.checkModule('location_mobile'))
                firstMenu.push({
                    name: 'Saved Location',
                    url: '/saved-location'
                });

            if (this.checkModule('single_target'))
                firstMenu.push({
                    name: 'Target',
                    url: '/target'
                });

            if (this.checkModule('case_management'))
                firstMenu.push({
                    name: 'Case Management',
                    url: '/case-management'
                });

            if (this.checkModule('license'))
                firstMenu.push({
                    name: 'License',
                    url: '/license'
                });

            if (this.checkModule('users'))
                firstMenu.push({
                    name: 'Users',
                    url: '/users'
                });
            
            return firstMenu;
        },
        secondMenu() {
            let secondMenu = [];

            if (this.checkModule('request_access'))
                secondMenu.push({
                    name: 'History',
                    url: '/history'
                });

            if (this.checkModule('tracking_target') || this.checkModule('tactical_device'))
                secondMenu.push({
                    name: 'Activity Log',
                    url: '/activity-log'
                });
            
            return secondMenu;
        },
    },
    watch: {
        target_list(newData) {
            if (newData.data && newData.data.length > 0) {
                this.target_list_data = newData.data;
            } else {
                this.target_list_data = []
            }
        },
        route(newData) {
            // console.log('watch route', newData)
        },
        modalTracking(newData, oldData, getQuota=false) {
            if (newData === true && oldData === false) {
                // this.$store.dispatch('dashboard/getQuotaInfo');
                if (getQuota) {
                    this.getQuotaInfo();
                }

                var input = document.getElementById("input-search-target");
                if (input)
                    input.select();

                this.setEmpty();
            }
        },
        // firstMenu() {},
        // secondMenu() {},
    },
    methods: {
        getQuotaInfo() {
            this.$store.dispatch('dashboard/getQuotaInfo');
        },
        setEmpty(){
            this.target_keyword = '';
            this.target_selected = {};
            this.target_list_data = [];
        },
        searchTarget() {
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                self.$store.commit('target/setSearchList', {});

                if (self.target_keyword.length > 2) {
                    // self.$store.dispatch('target/getSearchList', [30, 1, self.target_keyword, null, null, null, null, JSON.stringify([self.case_selected.id])]);
                    self.$store.dispatch('target/getSearchList', [30, 1, self.target_keyword]);
                }
            }, 650);
        },
        selectTarget(data) {
            if (this.filterMsisdn(data.clues) === '') {
                Event.$emit('error', "This target doesn't have MSISDN.");
                return;
            }

            this.target_selected = data;
            this.target_keyword = '';
            this.$store.commit('target/setSearchList', {});
        },
        setEmptyTarget() {
            this.target_selected = {};
        },
        filterMsisdn(clues, use_prefix=true) {
            let msisdn = '';
            let filtered = clues.filter(function(item) {
                return item.name == 'msisdn';
            });

            if (filtered[0] && filtered[0].value)
                msisdn = (use_prefix ? '- ' : '')+ filtered[0].value;

            return msisdn;
        },
        search() {

            if (Object.keys(this.target_selected).length > 0) {
                let key = this.filterMsisdn(this.target_selected.clues, false);
                let type = 'msisdn';

                // this.$router.push({ name: 'SearchResult', params: {key: encrypt(key + '&&' + type) } });
                this.$router.push({ name: 'SearchResult', params: { 
                    key: encrypt(key + '&&' + type),
                    target_id: this.target_selected.id,
                } });
                this.modalTracking = false;

            } else {

                Event.$emit('error', "Please choose target first.");
            }
        },
        checkModule(module_label, sub_module_label=null) {
            let result = false;

            if ((this.user && this.user.role && (this.user.role.label === 'superadmin') || (this.user.role.module && this.user.role.module.length > 0))) {
                let check_module = []
                if (this.user.role.label !== 'superadmin') {
                    check_module = this.user.role.module.filter(function(item) {
                        return item.label === module_label;
                    });
                }

                if (sub_module_label === null) {
                    if (check_module.length > 0 || this.user.role.label === 'superadmin')
                        result = true;

                } else {
                    if ((check_module.length > 0 && check_module[0].function && check_module[0].function.length > 0) || this.user.role.label === 'superadmin') {

                        let get_sub_module = [];

                        if (check_module.length > 0 && check_module[0].function && check_module[0].function.length > 0) {
                            get_sub_module = check_module[0].function.filter(function(item) {
                                return item.label === sub_module_label;
                            });
                        }

                        if (get_sub_module.length > 0 || this.user.role.label === 'superadmin')
                            result = true;
                    }
                }
            }

            return result;
        },
    },
    mounted() {
        Event.$on('openModalTracking', () => {
            this.modalTracking = !this.modalTracking;
        });
    },
}
</script>
