<template lang="pug">
    .profile-page
        .container
            section.box.py-8.m-auto(style="max-width:560px")
                header.box__header.flex.items-center.justify-between.mb-8
                    h2.text-2xl.font-bold Profile
                .box__body
                    .field
                        .field__label
                            label Full Name *
                        .field__form(v-if="status_profile.status === 'loading'")
                            .field-style
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                        .field__form(v-else)
                            input.field-style(
                                type="text" 
                                placeholder="Write name here"
                                v-model="model.name"
                            )
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.name.$error") This name is required &amp; max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_name !== ''") {{ error_name }}
                    .field
                        .field__label
                            label Username *
                        .field__form(v-if="status_profile.status === 'loading'")
                            .field-style
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                        .field__form(v-else)
                            input.field-style.field-style--disabled(type="text" placeholder="Write username here" v-model="model.username" disabled)
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.username.$error") This username is required, max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_username !== ''") {{ error_username }}
                            
                    
                    .field
                        .field__label
                            label Email Registered *
                        .field__form(v-if="status_profile.status === 'loading'")
                            .field-style
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                        .field__form(v-else)
                            input.field-style.field-style--disabled(type="email" placeholder="Write email here" v-model="model.email" disabled)
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.email.$error") This email is required, max length is 255 char, &amp; must an email format.
                                .message.text-xs.text-red-500(v-if="error_email !== ''") {{ error_email }}
                            
                    //- .field
                        .field__label
                            label New Password
                        .field__form
                            input.field-style(
                                type="password"
                                placeholder="Write new password here"
                                v-model="model.password"
                            )
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.password.$error") This password minimum 6 characters{{ route_name === 'pageUsersCreate' ? ', required, &amp; ' : ' &amp;'}} max length is 255 char.
                                .message.text-xs.text-red-500(v-if="error_password !== ''") {{ error_password }}
                    
                    //- .field
                        .field__label
                            label Confrim New Password
                        .field__form
                            input.field-style(
                                type="password"
                                placeholder="Re-write new password" 
                                v-model="model.password_confirmation"
                            )
                            .pt-3
                                .message.text-xs.text-red-500(v-if="$v.model.password_confirmation.$error") This password confirmation must be same as password.
                                .message.text-xs.text-red-500(v-if="error_password_confirmation !== ''") {{ error_password_confirmation }}

                    .field
                        button.btn-primary(
                            type="submit" 
                            @click="submit($event)"
                        ) Update
            .divider
                span
                span
                span
            section.box.py-8.m-auto.border-t.border-gray-900.mb-12(style="max-width:560px")
                .text-red-400.cursor-pointer(@click="logout()") Logout
            //- section.box.py-8.m-auto(style="max-width:560px")
                .box__body
                    .field
                        button.btn-primary Logout
</template>

<script>
import { mapState } from 'vuex';
import { required, requiredIf, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'Profile',
    components: {
        Spinner,
    },
    data() {
        return {
            model: {
                name: '',
                username: '',
                email: '',
                phone: '',
                client_id: '',
                role_id: '',
                status: '',
                password: '',
                password_confirmation: '',
            },
            // error field
            error_name: '',
            error_username: '',
            error_email: '',
            error_phone: '',
            error_client_id: '',
            error_role_id: '',
            error_status: '',
            error_password: '',
            error_password_confirmation: '',

        };
    },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(255)
            },
            username: {
                required,
                maxLength: maxLength(255)
            },
            email: {
                required,
                maxLength: maxLength(255),
                email
            },
            phone: {
                maxLength: maxLength(255)
            },
            client_id: {
                // required,
            },
            role_id: {
                // required,
            },
            status: {
                // required,
            },
            password: {
                minLength: minLength(6),
                maxLength: maxLength(255),

                // goodPassword: (password) => { //a custom validator!
                //     return password.length >= 6
                //     // /[a-z]/.test(password) &&
                //     // /[A-Z]/.test(password) &&
                //     // /[0-9]/.test(password)
                // },
            },
            password_confirmation: {
                required: requiredIf(function() {
                    return this.model.password.length > 0;
                }),
                minLength: minLength(6),
                maxLength: maxLength(255),
                sameAsPassword: sameAs('password')
            },
        },
    },
    watch: {
        route_name() {
            this.getData();
        },
        // form data
        profile(newData) {
            this.model.name = (newData.name ? newData.name : '');
            this.model.username = (newData.username ? newData.username : '');
            this.model.email = (newData.email ? newData.email : '');
            this.model.phone = (newData.phone ? newData.phone : '');
            this.model.role_id = (newData.role && newData.role.name ? newData.role.name : '');
            this.model.client_id = (newData.client && newData.client.name ? newData.client.name : '');
            this.model.status = (newData.status ? (newData.status === 1 || newData.status === '1' ? 'Active' : 'Inactive') : '');
        },
    },
    methods: {
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_name = '';
                this.error_username = '';
                this.error_email = '';
                this.error_phone = '';
                this.error_role_id = '';
                this.error_client_id = '';
                this.error_status = '';
                this.error_password = '';
                this.error_password_confirmation = '';
            } else if (empty !== true && errors !== null) {
                this.error_name = (errors.name ? errors.name : '');
                this.error_username = (errors.username ? errors.username : '');
                this.error_email = (errors.email ? errors.email : '');
                this.error_phone = (errors.phone ? errors.phone : '');
                this.error_client_id = (errors.client_id ? errors.client_id : '');
                this.error_role_id = (errors.role_id ? errors.role_id : '');
                this.error_status = (errors.status ? errors.status : '');
                this.error_password = (errors.password ? errors.password : '');
                // this.error_password_confirmation = (errors.password_confirmation ? errors.password_confirmation : '');
            }
        },
        getData(update=false) {
            this.reset(true);
            // console.log(this.route_name)
            if (this.route_name === 'ProfilePage') {
                
                // if (!update) 
                    // Event.$emit('showLoading', true);

                this.$store.dispatch('auth/getProfile')
                .then((resp) => {
                    if (resp === 'success') {
                        // Event.$emit('showLoading', false);

                        if (!update) {
                            Event.$emit('showLoading', false);
                        } else {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully updated!',
                                timer: 3000,
                            });
                        }

                    } else {
                        Event.$emit('error');
                    }
                });
            }
        },
        reset(direct=false) {
            if (direct === true) {
                // this.setEmptyModel(false);
                this.setEmptyModel(true);
                // reset vuelidate
                this.$nextTick(() => { this.$v.$reset(); });
                this.setErrors(true);
                window.scrollTo(0,0);
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to reset form?',
                    // text: id,
                    confirmButtonText: 'Yes!',
                    confirmButtonColor: '#DD6B55',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.getData();
                    }
                });
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                window.scrollTo(0,0);
                return;
            }

            Event.$emit('showLoading', true);
            let model_data = Object.keys(this.model).filter((item) => { return (item !== 'password_confirmation' && item !== 'client_id' && item !== 'role_id' && item !== 'status'); }).map((key) => this.model[key]);

            this.$store.dispatch('auth/updateProfile', model_data)
            .then((resp) => {
                if (resp.result === 'success') {                        
                    this.getData(true);
                } else {
                    if (resp.data.message && resp.data.message.errors) {
                        this.setErrors(false, resp.data.message.errors);
                        Event.$emit('showLoading', false);
                        window.scrollTo(0,0);
                    } else {
                        Event.$emit('error');
                    }
                }
            });
        },
        logout() {
            Event.$emit('showLoading');
            
            this.$store.dispatch('auth/logout').then((resp) => {

                if (resp.result && resp.result === 'success') {
                    this.$router.push('/login');
                    Event.$emit('showLoading', false);
                } else {
                    this.$swal.fire({
                        title: 'Failed logout!',
                        icon: 'error',
                        text: (resp.data && resp.data.message ? resp.data.message : 'Something wrong.'),
                    });
                }
            });
        },
    },
    computed: {
        ...mapState('auth', [
            'user',
            'profile',
            'status_profile'
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    mounted() {
        this.getData();
    },
}
</script>
