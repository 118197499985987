<template lang="pug">
    .container
        section.box.my-8
            header.box__header.flex.items-center.justify-between.mb-8
                h2.text-2xl.font-bold List of Case
                button.btn-primary(
                    @click="modalCase = !modalCase; form_type = true;"
                ) Add New Case

            .box__body
                .main-table
                    table.table
                        thead
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}

                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")

                            template(v-else-if="list.data && list.data.length > 0")
                                tr(v-for="(data, index) in list.data" :key="index")
                                    td 
                                        button.link.text-sm(
                                            @click="detailModal(data.id)"
                                        ) {{ data.operation_name ? data.operation_name : '-' }}

                                    td 
                                        .text-sm {{ data.location ? data.location : '-' }}
                                    td
                                        .text-sm.w-60.truncate {{ data.description ? data.description : '-' }}
                                    td
                                        div(:class="data.status.toLowerCase() === 'active' ? 'label-green' : 'label-gray'") {{ data.status }}
                                    td 
                                        .text-xs {{ data.created_at_view? data.created_at_view : (data.created_at? data.created_at:'-') }}
                                    td
                                        .flex.items-center.justify-end

                                            button.btn-icon(
                                                @click="editModal(data.id)"
                                                v-tooltip="'Edit'"
                                            )
                                                IconSet(type="edit" size="s")
                                            template(v-if="user.role && user.role.label && (user.role.label === 'superadmin' || user.role.label === 'admin')")
                                                button.btn-icon(
                                                    @click="deleteData(data.id, data.operation_name)"
                                                    v-tooltip="'Delete'"
                                                )
                                                    IconSet(type="delete" size="s")

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available

            .box__footer(v-if="status_list.status !== 'loading'")
                .box-table-footer
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )

        .overlay(v-bind:class="{'is-show': modalCase}")
            .overlay__container
                .box-overlay
                    header.box-overlay__header.flex.items-center.justify-between
                        h2.text-xl.font-bold {{ form_type ? 'Add' : 'Edit'}} Case
                        button.btn-icon(
                            @click="modalCase = !modalCase"
                        )
                            IconSet(
                                type="close"
                            )
                    form.box-overlay__body(@submit="submit($event)")
                        .field
                            .field__label
                                label(for="name") Case Name <sup>*</sup>
                            .field__form
                                input.field-style(
                                    type="text"
                                    placeholder="Write target name here"
                                    v-model="model.operation_name"
                                )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.operation_name.$error") This case name is required &amp; max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_operation_name !== ''") {{ error_operation_name }}
                        .field
                            .field__label
                                label(for="location") Location <sup>*</sup>
                            .field__form
                                input.field-style(
                                    type="text"
                                    placeholder="Write location here"
                                    v-model="model.location"
                                )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.location.$error") This location is required &amp; max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_location !== ''") {{ error_location }}

                        template(v-if="user.role && user.role.label && (user.role.label === 'superadmin')")
                            .field
                                .field__label
                                    label(for="search") Assign

                                .field__form
                                    .relative(v-if="status_tactical_list.status === 'loading'")
                                        .field-style.field-style--mirroring.field-style--loading
                                            .absolute.w-full.h-full.flex.items-center.justify-center
                                                Spinner(show="true" size="32")

                                    .relative(v-else)
                                        template(v-if="tactical_list.data && tactical_list.data.length > 0")
                                            select.field-style(v-model="model.assign_id")
                                                option(selected value="") - Select Tactical User -
                                                option(v-for="opt in tactical_list.data" :key="opt.id" :value="opt.id") {{ opt.name }}
                                            .field-icon
                                                IconSet(
                                                    type="chevron-down"
                                                )
                                        input.field-style(
                                            v-else
                                            :disabled="true"
                                            type="text"
                                            placeholder="No tactical users data"
                                        )


                                    .py-3
                                        //- .message.text-xs.text-red-500(v-if="$v.model.assign_id.$error") This assign is required.
                                        .message.text-xs.text-red-500(v-if="error_assign_id !== ''") {{ error_assign_id }}

                        .field
                            .field__label
                                label(for="search") Status <sup>*</sup>
                            .field__form
                                .grid.grid-cols-2.gap-4.mt-8
                                    .block
                                        .ui-radio                                    
                                            input(type="radio" name="status" value="1" v-model="model.status")
                                            .style-radio
                                                .radio
                                                span Active

                                    .block
                                        .ui-radio
                                            input(type="radio" name="status" value="0" v-model="model.status")
                                            .style-radio
                                                .radio
                                                span Inactivate
                                .pt-3
                                    .message.text-xs.text-red-500(v-if="$v.model.status.$error") This status is required.
                                    .message.text-xs.text-red-500(v-if="error_status !== ''") {{ error_status }}

                        .field
                            .field__label
                                label(for="search") Description
                            .field__form
                                textarea.field-style(
                                    type="text"
                                    placeholder="Write a Description here"
                                    v-model="model.description"
                                )
                                .pt-3
                                    //- .message.text-xs.text-red-500(v-if="$v.model.description.$error") This description is required.
                                    .message.text-xs.text-red-500(v-if="error_description !== ''") {{ error_description }}
                        
                        .field.flex.items-center.justify-end
                            button.btn-primary(
                                type="submit" 
                            ) SAVE


        .overlay(v-bind:class="{'is-show': modalCaseDetail}")
            .overlay__container
                .box-overlay
                    header.box-overlay__header.flex.items-center.justify-between
                        h2.text-xl.font-bold Case Detail
                        button.btn-icon(
                            @click="modalCaseDetail = !modalCaseDetail"
                        )
                            IconSet(
                                type="close"
                            )
                    .box-overlay__body
                        .mb-6
                            span.block.mb-2.opacity-50.text-sm Case Name
                            p {{ detail.operation_name ? detail.operation_name : '-' }}
                            
                        .mb-6
                            span.block.mb-2.opacity-50.text-sm Location
                            p {{ detail.location ? detail.location : '-' }}

                        .mb-6
                            span.block.mb-2.opacity-50.text-sm Description
                            p {{ detail.description ? detail.description : '-' }}
                        
                        div(v-if="detail.status" :class="detail.status.toLowerCase() === 'active' ? 'label-green' : 'label-gray'") {{ detail.status }}

</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import IconSet from '@/components/IconSet.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { required, maxLength } from "vuelidate/lib/validators";

export default {
    name: "CaseManagement",
    components: {
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        IconSet,
    }, 
    data(){
        return {
            tableThead: [
                {
                    name: 'Case',
                },{
                    name: 'Location',
                },{
                    name: 'Description',
                },{
                    name: 'Status',
                },{
                    name: 'Created At',
                },{
                    name: '',
                    action: true,
                }
            ],

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            order_by: '',
            keyword: '',
            timeout: null,

            modalCase: false,
            modalCaseDetail: false,
            form_type: true,
            case_id_selected: '',

            model: {
                operation_name: '',
                location: '',
                assign_id: [],
                // assign_id: '',
                status: '',
                description: '',
            },

            // error field
            error_operation_name: '',
            error_location: '',
            error_assign_id: '',
            error_status: '',
            error_description: '',
        };
    },
    validations: {
        model: {
            operation_name: {
                required,
                maxLength: maxLength(255)
            },
            location: {
                required,
                maxLength: maxLength(255)
            },
            assign_id: {
                // 
            },
            status: {
                required,
            },
            description: {
                // 
            },
        },
    },
    computed: {
        ...mapState('caseManagement', [
            'status_list',
            'status_detail',
            'list',
            'detail',
        ]),
        ...mapState('users', [
            'status_tactical_list',
            'tactical_list',
        ]),
        ...mapState('auth', ['user']),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData) {
            if (newData === 'CaseManagement') {
                // this.total_data = 0;
                this.items_per_page = 10;
                this.page = 0;
                this.keyword = '';
                this.$store.commit('caseManagement/setList', {});

                this.getData();
            }
        },
        list(newData){
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        modalCase(newData, oldData) {
            if (newData === false)
                this.reset();

            if (this.user.role && this.user.role.label && (this.user.role.label === 'superadmin')) {                
                if (newData === true && oldData === false) {
                    this.$store.dispatch('users/getTacticalList', [100, 1]);
    
                } else {
    
                    this.$store.commit('users/setTacticalList', {});
                    this.reset();
                }
            }
        },
        modalCaseDetail(newData) {
            if (newData === false)
                this.$store.commit('caseManagement/setDetail', {});
        },
        detail(newData) {
            if (this.form_type === false) {
                this.case_id_selected = (newData.id ? newData.id : '');
                this.model.operation_name = (newData.operation_name ? newData.operation_name : '');
                this.model.location = (newData.location ? newData.location : '');
                this.model.assign_id = (newData.assign_id ? newData.assign_id : []);
                this.model.status = (newData.status ? (newData.status.toLowerCase() === 'active' ? '1' : '0') : '');
                this.model.description = (newData.description ? newData.description : '');
            }
        },
    },
    methods: {
        getData() {
            // for dispatch get data
            if (this.route_name === 'CaseManagementPage') {
                this.$store.dispatch('caseManagement/getList', [this.items_per_page, (this.page + 1), this.keyword, this.order_by, this.from_date, this.to_date]);
                // this.$store.dispatch('auth/getProfile');
                this.case_id_selected = '';
            }
        },

        // for create or update target
        editModal(id) {
            this.modalCase = !this.modalCase;
            this.form_type = false;
            this.case_id_selected = id;
            this.getDetail(id);
        },
        detailModal(id) {
            this.modalCaseDetail = !this.modalCaseDetail
            this.getDetail(id);
        },
        getDetail(id) {
            this.reset();

            // this.$store.dispatch('caseManagement/getSearchList', [100, 1]);

            Event.$emit('showLoading', true);

            this.$store.dispatch('caseManagement/getDetail', id)
            .then((resp) => {
                if (resp === 'success') {

                    Event.$emit('showLoading', false);

                } else {
                    Event.$emit('error');
                }
            });

        },
        reset() {
            this.$store.commit('caseManagement/setDetail', {});
            this.case_id_selected = '';
            this.setEmptyModel();
            // reset vuelidate
            this.$nextTick(() => { this.$v.$reset(); });
            this.setErrors(true);
            window.scrollTo(0,0);
        },
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_operation_name = '';
                this.error_location = '';
                this.error_assign_id = '';
                this.error_status = '';
                this.error_description = '';

            } else if (empty !== true && errors !== null) {
                this.error_operation_name = (errors.operation_name ? errors.operation_name : '');
                this.error_location = (errors.location ? errors.location : '');
                this.error_assign_id = (errors.assign_id ? errors.assign_id : '');
                this.error_status = (errors.status ? errors.status : '');
                this.error_description = (errors.description ? errors.description : '');
            }
        },
        // submit(e, save_continue=false) {
        submit(e) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // Event.$emit('error', 'Please fill the form correctly.');
                // Event.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            Event.$emit('showLoading', true);

            let model_data = Object.keys(this.model).map((key) => this.model[key]);
            model_data = Object.keys(this.model).map(
                (key) => (key !== 'assign_id') ? this.model[key] : (this.model[key].length > 0 ? [JSON.stringify(this.model[key])] : null)
            );

            if (this.form_type === true) {

                this.$store.dispatch('caseManagement/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        Event.$emit('success', 'Data successfully created!');
                        this.modalCase = false;
                        this.getData();

                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });

            // edit/update
            } else {

                model_data = [this.case_id_selected].concat(model_data);

                this.$store.dispatch('caseManagement/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        Event.$emit('success', 'Data successfully created!');
                        this.modalCase = false;
                        this.getData();

                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });
            }
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Case : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    Event.$emit('showLoading', true);

                    this.$store.dispatch('caseManagement/delete', id)
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;
                            this.getData();
                            Event.$emit('success', 'Data successfully deleted!');

                        } else {
                            if (resp.data && resp.data.message) {
                                Event.$emit('error', resp.data.message);
                            } else {
                                Event.$emit('error');
                            }
                        }
                    });
                }
            });
        },
    },
    mounted() {
        this.getData();
    },
}
</script>