<template lang="pug">
    .container
        section.box.my-8
            header.box__header.flex.items-center.justify-between.mb-8
                h2.text-2xl.font-bold List of Users

            .box__body
                .box-filter.mb-4
                    .flex.items-center
                        .field.relative.mr-3.field-filter
                            input.field-style(
                                v-model="keyword"
                                type="text"
                                placeholder="Search here..."
                                @keyup="searchKeyword"
                            )
                            .field-icon
                                IconSet(
                                    type="search"
                                )
                .main-table
                    table.table
                        thead
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}

                        tbody
                            tr(v-if="status_list.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")

                            template(v-else-if="list.data && list.data.length > 0")
                                tr(v-for="(data, index) in list.data" :key="index")
                                    td {{ data.name ? data.name : '-' }}
                                    td {{ data.username ? data.username : '-' }}
                                    td {{ data.email ? data.email : '-' }}
                                    td
                                        div(:class="data.status.toLowerCase() === 'active' ? 'label-green' : 'label-gray'") {{ data.status }}
                                    td
                                        .flex.items-center.justify-end

                                            button.btn-icon(
                                                @click="editModal(data.id)"
                                                v-tooltip="'Edit'"
                                            )
                                                IconSet(type="edit" size="s")

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available

            .box__footer(v-if="status_list.status !== 'loading'")
                .box-table-footer
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )

        .overlay(v-bind:class="{'is-show': modalUsers}")
            .overlay__container
                .box-overlay
                    header.box-overlay__header.flex.items-center.justify-between
                        h2.text-xl.font-bold {{ form_type ? 'Add' : 'Edit'}} User
                        button.btn-icon(
                            @click="modalUsers = !modalUsers"
                        )
                            IconSet(
                                type="close"
                            )
                    form.box-overlay__body(@submit="submit($event)")
                        .field
                            .field__label
                                label(for="name") Name <sup>*</sup>
                            .field__form
                                input.field-style(
                                    type="text"
                                    placeholder="Write name here"
                                    v-model="model.name"
                                    disabled
                                )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.name.$error") This name field is required &amp; max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_name !== ''") {{ error_name }}
                        .field
                            .field__label
                                label(for="username") Username <sup>*</sup>
                            .field__form
                                input.field-style(
                                    type="text"
                                    placeholder="Write username here"
                                    v-model="model.username"
                                    disabled
                                )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.username.$error") This username field is required &amp; max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_username !== ''") {{ error_username }}

                        .field
                            .field__label
                                label(for="username") Email <sup>*</sup>
                            .field__form
                                input.field-style(
                                    type="text"
                                    placeholder="Write email here"
                                    v-model="model.email"
                                    disabled
                                )
                                .py-3
                                    .message.text-xs.text-red-500(v-if="$v.model.email.$error") This email field is required &amp; max length is 255 char.
                                    .message.text-xs.text-red-500(v-if="error_email !== ''") {{ error_email }}                                    

                        .field
                            .field__label
                                label(for="search") Status <sup>*</sup>
                            .field__form
                                .grid.grid-cols-2.gap-4.mt-8
                                    .block
                                        .ui-radio                                    
                                            input(type="radio" name="status" value="1" v-model="model.status")
                                            .style-radio
                                                .radio
                                                span Active

                                    .block
                                        .ui-radio
                                            input(type="radio" name="status" value="0" v-model="model.status")
                                            .style-radio
                                                .radio
                                                span Deactivate
                                .pt-3
                                    .message.text-xs.text-red-500(v-if="$v.model.status.$error") This status is required.
                                    .message.text-xs.text-red-500(v-if="error_status !== ''") {{ error_status }}

                        .field.flex.items-center.justify-end
                            button.btn-primary(
                                type="submit" 
                            ) SAVE

</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import IconSet from '@/components/IconSet.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";

export default {
    name: "Users",
    components: {
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        IconSet,
    },
    data() {
        return {
            tableThead: [
                {
                    name: 'Name',
                },{
                    name: 'Username',
                },{
                    name: 'Email',
                },{
                    name: 'Status',
                },{
                    name: '',
                    action: true,
                }
            ],

            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            modalUsers: false,
            form_type: true,
            user_id_selected: '',

            keyword: '',
            timeout: null,
            monitoring_active:false,

            model: {
                name: '',
                email: '',
                phone: '',
                role_id: '',
                status: '',
                password: null,
            },  
            
            error_name: '',
            error_username: '',
            error_role_id: '',
            error_email: '',            
            error_status: '',            

        };
    },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(255)
            },
            username: {
                maxLength: maxLength(255)
            },
            role_id: {
            },
            status: {
                required,
            },
            email: {
                required,
            },            
        },
    },
    computed: {
        ...mapState('users',[
            'status_list',
            'status_detail',
            'list',
            'detail',
        ]),
        ...mapState('auth', [
            'user',
        ]),        
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageUsers') {
                // this.total_data = 0;
                this.items_per_page = 10;
                this.page = 0;
                this.keyword = '';
                this.$store.commit('users/setList', {});

                this.getData();
            }
        },
        list(newData){
            if (Object.keys(newData).length > 0) {
                this.total_data = newData?.total_data ? parseInt(newData.total_data) : 0;
            } else {
                this.total_data = 0;
            }
        },
        modalUsers(newData) {
            if (newData === false)
                this.reset();
        },        
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        client_id() {
            this.getData();
        },
        detail(newData) {
            if (this.form_type === false) {
                this.user_id_selected = (newData.id ? newData.id : '');
                this.model.name = (newData.name ? newData.name : '');
                this.model.username = (newData.username ? newData.username : '');
                this.model.phone = (newData.phone ? newData.phone : '');
                this.model.email = (newData.email ? newData.email : '');
                this.model.role_id = (newData.role && newData.role.id ? newData.role.id : '');
                this.model.status = (newData.status ? (newData.status.toLowerCase() === 'active' ? '1' : '0') : '');
            }
        },        
    },
    methods: {
        getData() {
            if (this.route_name === 'pageUsers') {
                this.$store.dispatch('users/getList', [this.items_per_page, (this.page + 1), this.keyword, null, null, null, (Object.keys(this.user).length > 0 && this.user.client && this.user.client.id ? this.user.client.id : '')]);                
                this.user_id_selected = '';
            }
        },
        // for create or update target
        editModal(id) {
            this.modalUsers = !this.modalUsers;
            this.form_type = false;
            this.user_id_selected = id;
            this.getDetail(id);
        },
        getDetail(id) {
            this.reset();
            Event.$emit('showLoading', true);

            this.$store.dispatch('users/getDetail', id)
            .then((resp) => {
                if (resp === 'success') {

                    Event.$emit('showLoading', false);

                } else {
                    Event.$emit('error');
                }
            });

        },
        reset() {
            this.$store.commit('users/setDetail', {});
            this.user_id_selected = '';
            this.setEmptyModel();
            // reset vuelidate
            this.$nextTick(() => { this.$v.$reset(); });
            this.setErrors(true);
            window.scrollTo(0,0);
        },
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_name = '';
                this.error_username = '';
                this.error_status = '';

            } else if (empty !== true && errors !== null) {
                this.error_name = (errors.name ? errors.name : '');
                this.error_username = (errors.username ? errors.username : '');
                this.error_status = (errors.status ? errors.status : '');
            }
        },
        // submit(e, save_continue=false) {
        submit(e) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                window.scrollTo(0,0);
                return;
            }

            Event.$emit('showLoading', true);

            let model_data = Object.keys(this.model).map((key) => this.model[key]);

            if (this.form_type === true) {

                this.$store.dispatch('users/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        Event.$emit('success', 'Data successfully created!');
                        this.modalUsers = false;
                        this.getData();

                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });

            // edit/update
            } else {

                model_data = [this.user_id_selected].concat(model_data);

                this.$store.dispatch('users/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {                        
                        Event.$emit('success', 'Data successfully created!');
                        this.modalUsers = false;
                        this.getData();

                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });
            }
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Target : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    Event.$emit('showLoading', true);

                    this.$store.dispatch('users/delete', id)
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;
                            this.getData();
                            Event.$emit('success', 'Data successfully deleted!');

                        } else {
                            if (resp.data && resp.data.message) {
                                Event.$emit('error', resp.data.message);
                            } else {
                                Event.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('users/setList', {});
                self.getData();
            }, 650);
        },
    },
    mounted() {
        this.getData();
    },
}
</script>
