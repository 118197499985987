<template lang="pug">
    .container
        section.box.my-8
            header.box__header.flex.items-center.justify-between.mb-8
                h2.text-2xl.font-bold List of Tracking History
                .flex.items-center
                    .bg-gray-900.text-yellow-100.px-4.py-2.rounded.mr-4.flex.items-center.text-sm
                        
                        .flex.items-center.justify-center.w-full(v-if="status_quota_info.status === 'loading'")
                            Spinner(show="true" size="24")

                        template(v-else-if="Object.keys(quota_info).length > 0")                            
                            template(v-if="quota_info.used === quota_info.quota")
                                p.text-xs Your quota has run out, please activate your access now by calling the admin.
                                p.font-bold.ml-8 Out of Quota {{ quota_info.used }}/{{ quota_info.quota }}

                            template(v-else)
                                p.mr-4 Your Daily Request Usage
                                p.font-bold {{ quota_info.used || quota_info.used === 0 ? quota_info.used : '-' }}/{{ quota_info.quota || quota_info.quota === 0 ? quota_info.quota : '-' }}

                        .flex.items-center.justify-center.w-full(v-else)
                            button.btn-icon-24(@click="getQuotaInfo()" v-tooltip="'Try again.'")
                                IconSet(type="refresh-cw")

                    button.btn-primary(
                        :disabled="status_quota_info.status !== 'loading' && Object.keys(quota_info).length > 0 && quota_info.used !== quota_info.quota ? false : 'disabled'"
                        @click="searchModal()"
                    ) Search new Target

            .box__body
                button.btn-primary-o.relative.mb-12
                    IconSet(type="calendar" size="s")
                    .btn-date-range.ml-3
                        date-range-picker(
                            v-if="dateRange.startDate && dateRange.endDate" 
                            ref="picker" 
                            v-model="dateRange" 
                            @update="pickerUpdate($event)" 
                            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }" 
                            :date-format="dateFormat" 
                            opens="right"
                            :ranges="default_ranges"
                            autoApply=true
                        )
                            div(
                                slot="input" 
                                slot-scope="picker") 
                                    | {{ picker.startDate | date}} - {{ picker.endDate  | date}}
                .main-table
                    table.table

                        thead
                            tr
                                th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}

                        tbody
                            tr(v-if="status_log_activity.status === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")

                            template(v-else-if="log_activity.data && log_activity.data.length > 0")
                                tr(v-for="(data, index) in log_activity.data" :key="index")
                                    td {{ data.created_at ? data.created_at : '-' }}

                                    template(v-if="data.response && data.response.code === 200 && data.response.message")
                                        td {{ jsonParse(data.response.message, 'target') }}
                                        td 
                                            .location-width.truncate {{ jsonParse(data.response.message, 'location') }}
                                    template(v-else)
                                        td -
                                        td -

                                    td
                                        .flex.items-center.justify-end

                                            //- button.btn-primary-o.btn-primary-o--disabled.mr-4.btn-small(v-if="status_quota_info.status === 'loading'" disabled="disabled")
                                                Spinner(show="true" size="20")

                                            //- template(v-else)
                                                template(v-if="Object.keys(quota_info).length > 0")

                                                    button.btn-primary-o.btn-primary-o--disabled.mr-4.btn-small.cursor-not-allowed(v-if="quota_info.used === quota_info.quota" disabled="disabled") Out of Quota

                                                    //- :to="{ name: 'SearchResult', params: {key: encrypt(data.key)} }"
                                                    router-link.btn-primary-o.mr-4.btn-small(
                                                        v-else
                                                        :to="{ name: 'SearchResult', params: {key: encrypt(data.key), target_id: data.id} }"
                                                    ) Visit Location

                                            router-link.btn-primary-o.mr-4.btn-small(
                                                v-if="data.response && data.response.code === 200"
                                                :to="{ name: 'SearchResultHistory', params: {data_type: 'tracking-history', history_data: encrypt(data.response.message)} }"
                                            ) Visit Location

                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available

            .box__footer(v-if="status_log_activity.status !== 'loading'")
                .box-table-footer
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )

</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import IconSet from '@/components/IconSet.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { encrypt } from '@/util/crypto';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';

export default {
    name: "Target",
    components: {
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        IconSet,
        DateRangePicker,
    }, 
    data(){
        return {

            tableThead: [
                {
                    name: 'Date Time',
                },{
                    name: 'Target',
                },{
                    name: 'Location',
                },{
                    name: '',
                    action: true,
                }
            ],

            from_date: '',
            to_date: '',
            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            order_by: '',
            keyword: '',
            timeout: null,
        }
    },
    computed: {
        ...mapState('users', [
            'status_log_activity',
            'log_activity',
        ]),
        ...mapState('dashboard', [
            'status_quota_info',
            'quota_info',
        ]),
        route_name() {
            return this.$route.name;
        },
    },
    watch: {
        route_name(newData) {
            if (newData === 'TrackingHistoryPage') {
                // this.total_data = 0;
                this.items_per_page = 10;
                this.page = 0;
                this.keyword = '';
                this.$store.commit('users/setLogActivity', {});

                this.getData();
            }
        },
        log_activity(newData){
            if (Object.keys(newData).length > 0) {
                this.total_data = newData?.total_data ? parseInt(newData.total_data) : 0;
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
    },
    methods: {
        getData() {
            // for dispatch get data
            if (this.route_name === 'TrackingHistoryPage') {
                setTimeout(() => this.getQuotaInfo(), 300);
                this.$store.dispatch('users/getLogActivity', [this.items_per_page, (this.page + 1), this.keyword, this.from_date, this.to_date, this.order_by]);
            }
        },
        getQuotaInfo() {
            this.$store.dispatch('dashboard/getQuotaInfo');
        },
        encrypt(data) {
            return encrypt(data);
        },
        jsonParse(msg, type) { // type = target/location
            let result = '-';
            try {
                msg = msg.replace("None None (0)", "None");
                // console.log('first msg', msg)
                msg = msg.replace(/'/g, '"').replace(/None/g, '"None"').replace(/\"\"None\"\"/g, '"None"').replace(/False/g, '"False"').replace(/'/g, '"').replace(/True/g, '"True"');
                // console.log('check msg', msg)
                const obj = JSON.parse(msg);
                // console.log('check obj', obj)
                // return []

                if (obj.features && obj.features[0].properties) {
                    const properties = obj.features[0].properties;

                    if (type === 'target' && properties.target_name)
                        result = properties.target_name;

                    else if (type === 'location' && properties.alamat)
                        result = properties.alamat;
                }

            } catch (error) {
                console.log('error jsonParse')
                console.log(error)
            }
            
            return result;
        },
        searchModal() {
            Event.$emit('openModalTracking');
        },

        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)

            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRange']) {
                let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                const default_date = this.$store.getters['config/getDefaultDate'](def);

                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.getData();
            }
        },
        datetimeFormat(datetime, format="DD MMMM YYYY hh:mm") {
            return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format(format);
        },
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
    },
    mounted() {

        if (this.route_name === 'TrackingHistoryPage') {
            // reset date filter

            let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
            const default_date = this.$store.getters['config/getDefaultDate'](def);

            this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.getData();
        }
    },
    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        },
        capitalize: function (s) {
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        },
    }
}
</script>

<style lang="sass">
    .location-width
        max-width: 35rem

    .btn-date-range
        .reportrange-text
            border: none
            padding: 0px

        .calendars.row
            display: flex
</style>
