import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelQuota;
const cancelToken = axiosOrigin.CancelToken;

const dashboard = {
	namespaced: true,
	state: {
		status_summary_data: {
			status: false,
			cancel: null,
			code: null,
		},
		summary_data: {},

		status_quota_info: {
			status: false,
			cancel: null,
			code: null,
		},
		quota_info: {},

		status_active_users: {
			status: false,
			cancel: null,
			code: null,
		},
		active_users: {},

		status_tactical_users: {
			status: false,
			cancel: null,
			code: null,
		},
		tactical_users: {},
	},
	mutations: {
		setStatusSummaryData: (state, status) => {
			state.status_summary_data = status;
		},

		setSummaryData: (state, data) => {
			state.summary_data = data;
		},

		setStatusQuotaInfo: (state, status) => {
			state.status_quota_info = status;
		},

		setQuotaInfo: (state, data) => {
			state.quota_info = data;
		},

		setStatusActiveUsers: (state, status) => {
			state.status_active_users = status;
		},

		setActiveUsers: (state, data) => {
			state.active_users = data;
		},

		setStatusTacticalUsers: (state, status) => {
			state.status_tactical_users = status;
		},

		setTacticalUsers: (state, data) => {
			state.tactical_users = data;
		},
	},
	getters: {},
	actions: {
		getSummaryData({
			state,
			commit,
			dispatch
		}) {
			if (state.status_summary_data.cancel != null && state.status_summary_data.status === 'loading')
				state.status_summary_data.cancel('Operation canceled by the user.');

			const source = cancelToken.source();

			commit('setStatusSummaryData', {
				status: 'loading',
				cancel: source.cancel,
				code: null,
			});

			axios.post('api/v1/request-access/quota-admin', {
					cancelToken: source.token
				})
				.then((resp) => {
					// console.log(resp.data)
					let data = resp.data;
					let code = resp.response && resp.response.status ? resp.response.status : null;
					commit('setSummaryData', data);

					commit('setStatusSummaryData', {
						status: true,
						cancel: null,
						code: code,
					});
				})
				.catch((resp) => {
					// console.log(resp)
					let code = resp.response && resp.response.status ? resp.response.status : null;
					commit('setStatusSummaryData', {
						status: 'failed',
						cancel: null,
						code: code,
					});
					dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
				});
		},

		getQuotaInfo({
			state,
			commit,
			dispatch
		}) {
			if (state.status_quota_info.cancel != null && state.status_quota_info.status === 'loading')
				state.status_quota_info.cancel('Operation canceled by the user.');

			axios.get('api/v1/request-access/quota-info', {
				cancelToken: new cancelToken(function executor(c) {
					cancelQuota = c;
					commit('setStatusQuotaInfo', {
						status: 'loading',
						cancel: c,
						code: null,
					});
				}),
			})
			.then((resp) => {
				// console.log(resp.data)
				let data = resp.data;
				let code = resp.response && resp.response.status ? resp.response.status : null;
				commit('setQuotaInfo', data);

				commit('setStatusQuotaInfo', {
					status: true,
					cancel: null,
					code: code,
				});
			})
			.catch((resp) => {
				// console.log(resp)
				let result = {
					status: 'failed',
					cancel: null,
					code: resp.response && resp.response.status ? resp.response.status : null,
				};
		
				if (axiosOrigin.isCancel(resp)) {
				result.status = 'loading';
				result.cancel = cancelQuota;
				}
		
				commit('setStatusQuotaInfo', result);
				dispatch('errors/handleApiResponse', [result.code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
			});
		},

		getActiveUsers({
			state,
			commit,
			dispatch,
			rootGetters
		}, [
			limit = '',
			page = '',
			keyword = '',
			order_by = '',
			from_date = '',
			to_date = ''
		]) {
			if (state.status_active_users.cancel != null && state.status_active_users.status === 'loading')
				state.status_active_users.cancel('Operation canceled by the user.');

			const source = cancelToken.source();

			commit('setStatusActiveUsers', {
				status: 'loading',
				cancel: source.cancel,
				code: null,
			});

			let params = new FormData();
			params.append('timezone', rootGetters['config/getTimezone']);

			params.append('limit', limit);
			params.append('page', page);
			params.append('keyword', keyword);
			params.append('order_by', order_by);
			params.append('from_date', from_date);
			params.append('to_date', to_date);

			axios.post('api/v1/request-access/active-users', params, {
				cancelToken: source.token
			})
			.then((resp) => {
				// console.log(resp.data)
				let data = resp.data;
				let code = resp.response && resp.response.status ? resp.response.status : null;
				commit('setActiveUsers', data);

				commit('setStatusActiveUsers', {
					status: true,
					cancel: null,
					code: code,
				});
			})
			.catch((resp) => {
				// console.log(resp)
				let code = resp.response && resp.response.status ? resp.response.status : null;
				commit('setStatusActiveUsers', {
					status: 'failed',
					cancel: null,
					code: code,
				});
				dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
			});
		},

		createAction({
			dispatch
		}, [user_id = null,
			quota = null,
			expired_at = null
		]) {
			let params = new FormData();
			params.append('user_id', user_id);
			params.append('quota', quota);
			params.append('expired_at', expired_at);

			return axios.post('api/v1/request-access/create', params)
				.then((resp) => {
					if (resp.status === 200) {
						return {
							result: 'success',
							data: resp.data
						};
					} else {
						return {
							result: 'failed',
							data: resp.data
						};
					}
				})
				.catch((resp) => {
					dispatch('errors/handleApiResponse', (resp.response && resp.response.status ? resp.response.status : null), {
						root: true
					});
					return {
						result: 'failed',
						data: (resp.response && resp.response.data ? resp.response.data : null)
					};
				});
		},

		updateAction({
			dispatch
		}, [id = null,
			quota = null,
			expired_at = null
		]) {
			let params = new FormData();
			params.append('id', id);
			params.append('quota', quota);
			params.append('expired_at', expired_at);

			return axios.post('api/v1/request-access/update', params)
				.then((resp) => {
					if (resp.status === 200) {
						return {
							result: 'success',
							data: resp.data
						};
					} else {
						return {
							result: 'failed',
							data: resp.data
						};
					}
				})
				.catch((resp) => {
					dispatch('errors/handleApiResponse', (resp.response && resp.response.status ? resp.response.status : null), {
						root: true
					});
					return {
						result: 'failed',
						data: (resp.response && resp.response.data ? resp.response.data : null)
					};
				});
		},

		stopAction({
			dispatch
		}, [id = null]) {
			let params = new FormData();
			params.append('id', id);
			params.append('status', 0);

			return axios.post('api/v1/request-access/change-status', params)
				.then((resp) => {
					if (resp.status === 200) {
						return {
							result: 'success',
							data: resp.data
						};
					} else {
						return {
							result: 'failed',
							data: resp.data
						};
					}
				})
				.catch((resp) => {
					dispatch('errors/handleApiResponse', (resp.response && resp.response.status ? resp.response.status : null), {
						root: true
					});
					return {
						result: 'failed',
						data: (resp.response && resp.response.data ? resp.response.data : null)
					};
				});
		},

		getTacticalUsers({
			state,
			commit,
			dispatch
		}, [
			limit = '',
			page = '',
			keyword = '',
			// order_by = '',
			// from_date = '',
			// to_date = ''
		]) {
			if (state.status_tactical_users.cancel != null && state.status_tactical_users.status === 'loading')
				state.status_tactical_users.cancel('Operation canceled by the user.');

			const source = cancelToken.source();

			commit('setStatusTacticalUsers', {
				status: 'loading',
				cancel: source.cancel,
				code: null,
			});

			let params = new FormData();
			params.append('limit', limit);
			params.append('page', page);
			params.append('keyword', keyword);
			// params.append('order_by', order_by);
			// params.append('from_date', from_date);
			// params.append('to_date', to_date);

			axios.post('api/v1/request-access/user-tactical', params, {
				cancelToken: source.token
			})
			.then((resp) => {
				let data = resp.data;
				let code = resp.response && resp.response.status ? resp.response.status : null;
				commit('setTacticalUsers', data);

				commit('setStatusTacticalUsers', {
					status: true,
					cancel: null,
					code: code,
				});
			})
			.catch((resp) => {
				// console.log(resp)
				let code = resp.response && resp.response.status ? resp.response.status : null;
				commit('setStatusTacticalUsers', {
					status: 'failed',
					cancel: null,
					code: code,
				});
				dispatch('errors/handleApiResponse', [code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
			});
		},
	}
};

export default dashboard;