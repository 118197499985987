import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";

import config from "./modules/config";
import auth from "./modules/auth";
import errors from "./modules/errors";
import dashboard from "./modules/dashboard";
import history from "./modules/history";
import activityLog from "./modules/activityLog";
import tacticalDevice from "./modules/tacticalDevice";
import caseManagement from "./modules/caseManagement";
import target from "./modules/target";
import recentLocation from "./modules/result/recentLocation";
import savedLocation from "./modules/savedLocation";
import users from "./modules/users";
import acl from "./modules/acl";
import license from "./modules/license";

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    config,
    auth,
    errors,
    dashboard,
    history,
    activityLog,
    tacticalDevice,
    caseManagement,
    target,
    recentLocation,
    savedLocation,
    users,
    acl,
    license,
  },
  plugins: [createPersistedState({
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    },
    paths: [
      'auth',
    ],
  })],
});
